import "./Loader.css";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingSpinner(props: {loadingText?: string, loadingSubtext?: string, clear?: boolean}) {
  return (
    <div className={`spinner-container${props.clear ? '' : '-bg'}`}>
      <div className="spinner-block">
        <CircularProgress size={'10rem'} sx={{color: '#f84d43'}} />
          {props.loadingText &&
            <div className="text-block">
                <p>{props.loadingText}</p>
              {props.loadingSubtext &&
                <p>({props.loadingSubtext})</p>
              }
            </div>
          }
      </div>
    </div>
  );
}