import { AxiosRequestHeaders } from "axios";

export default function authHeader(): AxiosRequestHeaders {
  const userStr = localStorage.getItem("user-data");
  let userData = null;
  if (userStr)
    userData = JSON.parse(userStr);
  if (userData && userData.access_token) {
    return { Authorization: 'Bearer ' + userData.access_token };
  } else {
    return {};
  }
}