import "./Firemap.css";
import { Component, createRef } from "react";
import ReactDOMServer from 'react-dom/server';
import { Icon, LatLngBounds, Layer } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, GeoJSON, LayersControl, ImageOverlay, LayerGroup, Marker, Popup, useMap } from "react-leaflet";
import markerIcon from "../Resources/Graphics/Icons/marker-icon.svg";
import markerShadow from "../Resources/Graphics/Icons/marker-shadow.png";
import markerIconRed from "../Resources/Graphics/Icons/fire-pin-graphic.png";
import markerIconBlack from "../Resources/Graphics/Icons/fire-pin-graphic_black.png";
import markerIconGrey from "../Resources/Graphics/Icons/fire-pin-graphic_grey.png";
import { GeoJsonObject } from "geojson";
import MapPredictionService from "../Services/map-predictions.service"
import Loader from "./Loader";
import "leaflet-timedimension-ts/src/leaflet.timedimension.control.css";
import { TimeDimension, TimeDimensionControlOptions, TimeDimensionOptions, Player } from "leaflet-timedimension-ts";
import axios from "axios";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css"
import Fullscreen, { FullscreenProps } from "react-leaflet-fullscreen-plugin";
import L from "leaflet";
import moment from 'moment';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { InciwebExternalData } from "./InciwebExternalData";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import WindyLayer from "./WindyLayer";
import WindyCams from "./WindyCams";
import markerIconCam from "../Resources/Graphics/Icons/marker-webcam3.svg";
import WindyCamsVideo from "./WindyCamsVideo";
import Control from "react-leaflet-custom-control";
import MapDatePicker from "./MapDatePicker";

type Props = {};
type State = {
  firePerimeterGeoJson: {
    collection: GeoJsonObject,
    date: string
  };
  firePerimeterPredictionGeoJson: {
    collection: GeoJsonObject,
    date: string
  };
  fireWindUSA: {
    collection: any,
    date: string
  }[];
  barometricLines: {
    collection: any,
    date: string
  }[];
  windyCams: GeoJsonObject;
  fireWindUSACollection: any[];
  loaded: boolean,
  snackbarOpen: boolean,
  snackbarMessage: string,
  alertSeverity: AlertColor,
  firePerimeterData: GeoJsonObject,
  fireSmokeDateTime: {
    datetime: string
  }[];
  fireSmokeData: GeoJsonObject,
  fireSmokeKey: string,
  noaaFiresmokeUSAData: any[],
  noaaFiresmokeAlaskaData: any[],
  blueskyFiresmokeData: any[],
  noaaFiresmokeUrlUSA: string,
  noaaFiresmokeUrlAlaska: string,
  blueskyFiresmokeUrl: string,
  isPaneOpenInciweb: boolean,
  inciwebTitle: string,
  inciwebName: string,
  isPaneOpenWindyCam: boolean,
  windyCamProps: any,
}

Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon,
  iconSize: [31, 31],
  iconAnchor: [16, 31],
  shadowUrl: markerShadow,
  shadowSize: [31, 31],
  shadowAnchor: [9, 31],
  popupAnchor: [0, -16]
});


export class Firemap extends Component<Props, State> {
  predictionDateToday = moment();
  predictionDateTomorrow = moment().subtract(-1, 'days');
  timeIndex = 0;
  prevDate: any;
  mapDefaultBounds = new LatLngBounds([20.2209657795223, -130.34179687500003], [55.10351605801967, -66.79687500000001]);
  mapDefaultZoom = 5;
  noaaUSABounds = new LatLngBounds([52.607415, -134.104638], [21.121230, -60.867517]);
  noaaAlaskaBounds = new LatLngBounds([77.064066, -179.910458], [41.558273, -72.469107]);
  blueskyBounds = new LatLngBounds([70.081192, -159.944767], [31.928293, -51.798974]);

  imgNoaaUSARef: any;
  imgNoaaAlaskaRef: any;
  imgBlueskyRef: any;
  firePerimeterPredictRef: any;
  firePerimeterRef: any;
  fireWindRef: any;
  leafletRef: any;
  layerControlRef: any;
  barometrictLinesRef: any;
  emptyGeoJson = {type: "FeatureCollection", features: []};
  currentTime = new Date();
  timeDimensionOptions: TimeDimensionOptions = {
    currentTime: '',
    times: "",
  };
  timeDimension = new TimeDimension(this.timeDimensionOptions);
  timeDimensionControlOptions: TimeDimensionControlOptions = {
    position: 'topright',
    dateFontWeight: 800,
    speedSlider: false,
    speedStep: 1,
    minSpeed: 1,
    maxSpeed: 10,
    onlyUTC: false,
    timeZones: ['LocalDay', 'local', 'UTC'],
    autoPlay: true,
    playerOptions: {
      transitionTime: 250,
      loop: true
    }
  };
  timeDimentionPlayer = new Player({}, this.timeDimension);

  windOptinons = {
    displayValues: false,
    displayOptions: {
      velocityType: "USA Wind",
      emptyString: "No wind data"
    },
    data: [],
    minVelocity: 0,
    maxVelocity: 20,
    opacity: 0.85,
    colorScale: ['#71c7ec', '#1ebbd7', '#189ad3', '#107dac', '#005073', '#063b00', '#0a5d00', '#089000', '#1fc600', '#0eff00', '#ffc100', '#ff9a00', '#ff7400', '#ff4d00', '#ff0000']
  }

  firePerimeterStyle = {
    fillColor: 'red',
    weight: 2,
    opacity: 1,
    color: 'red',
    strokeOpacity: 1,
    fillOpacity: 0.2
  };

  firePerimeterPredictionStyle = {
    fillColor: 'orange',
    weight: 2,
    opacity: 1,
    color: 'orange',
    strokeOpacity: 1,
    fillOpacity: 0.2
  };

  firePerimeterInciwebStyle = {
    fillColor: '#590000',
    weight: 5,
    opacity: 1,
    color: '#800000',
    strokeOpacity: 1,
    fillOpacity: 0.2
  };
  barometricLinesStyle = {
    weight: 2
  }

  fullscreenOptions: FullscreenProps = {
    position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
    title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
    titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
    content: '', // change the content of the button, can be HTML, default null
    forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
    forcePseudoFullscreen: false, // force use of pseudo full screen even if full screen API is available, default false
    fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container
  };
  isShowCameras = process.env.REACT_APP_IS_SHOW_CAMERAS;

  constructor(props: Props) {
    super(props);
    this.imgNoaaUSARef = createRef();
    this.imgNoaaAlaskaRef = createRef();
    this.imgBlueskyRef = createRef();
    this.firePerimeterPredictRef = createRef();
    this.firePerimeterRef = createRef();
    this.fireWindRef = createRef();
    this.leafletRef = createRef();
    this.barometrictLinesRef = createRef();
    this.layerControlRef = createRef();
    this.state = {
      firePerimeterGeoJson: {
        collection: {} as GeoJsonObject,
        date: ''
      },
      firePerimeterPredictionGeoJson: {
        collection: {} as GeoJsonObject,
        date: ''
      },
      barometricLines: [],
      fireWindUSA: [],
      fireWindUSACollection: [],
      windyCams: {} as GeoJsonObject,
      loaded: false,
      snackbarOpen: false,
      snackbarMessage: 'Somthing wrong!',
      alertSeverity: 'error',
      firePerimeterData: {} as GeoJsonObject,
      fireSmokeDateTime: [],
      fireSmokeData: {} as GeoJsonObject,
      fireSmokeKey: '',
      noaaFiresmokeUSAData: [],
      noaaFiresmokeAlaskaData: [],
      blueskyFiresmokeData: [],
      noaaFiresmokeUrlUSA: '',
      noaaFiresmokeUrlAlaska: '',
      blueskyFiresmokeUrl: '',
      isPaneOpenInciweb: false,
      isPaneOpenWindyCam: false,
      inciwebTitle: '',
      inciwebName: '',
      windyCamProps: {},
    };
    this.currentTime.setUTCDate(1);
  }

  componentDidMount() {
    this.getAllMapData();
    this.getPredictionPerimeterData();

    this.timeDimension?.on("timeload", (data: any) => {
      const timeIndex = data.target._currentTimeIndex;
      const startOfTomorrow = this.predictionDateTomorrow.startOf('day');
      const startOfToday = this.predictionDateToday.startOf('day');
      const diffBetweenTicks = moment(data.time).diff(this.prevDate);
      if (this.state.firePerimeterPredictionGeoJson.collection) {
        if (moment(data.time).diff(startOfTomorrow) === 0 || (moment(data.time).diff(startOfTomorrow) > 0 && Math.abs(diffBetweenTicks) > 3600000)) {
          const glPredict = this.firePerimeterPredictRef.current;
          const glPerimeter = this.firePerimeterRef.current;
          glPredict?.clearLayers().addData(this.state.firePerimeterPredictionGeoJson.collection);
          glPerimeter?.clearLayers().addData(this.state.firePerimeterGeoJson.collection);
        }
        if (moment(data.time).diff(startOfToday) === 0 || moment(data.time).diff(startOfTomorrow) === -3600000 || (moment(data.time).diff(startOfTomorrow) < 0 && Math.abs(diffBetweenTicks) > 3600000)) {
          const glPredict = this.firePerimeterPredictRef.current;
          glPredict?.clearLayers();
        }
      }
      this.prevDate = moment(data.time);
      const baroLines = this.barometrictLinesRef.current;
      if (this.state.barometricLines.length && this.state.barometricLines[timeIndex]?.collection) {
        baroLines?.clearLayers().addData(this.state.barometricLines[timeIndex]?.collection);
      }
    });

    this.timeDimension.on("timeloading", (data) => {
      const timeIndex = data.target._currentTimeIndex;
      const ilUSA = this.imgNoaaUSARef.current;
      const ilAlaska = this.imgNoaaAlaskaRef.current;
      const ilBluesky = this.imgBlueskyRef.current;
      ilUSA?.setUrl(this.state.noaaFiresmokeUSAData[timeIndex]?.url);
      ilAlaska?.setUrl(this.state.noaaFiresmokeAlaskaData[timeIndex]?.url);
      ilBluesky?.setUrl(this.state.blueskyFiresmokeData[timeIndex]?.url);
    });

  }

  getAllMapData() {
    axios
      .all([
        MapPredictionService.getFirePerimeterMap(this.predictionDateToday.format('YYYY-MM-DD')),
        MapPredictionService.getNoaaFireSmokeMap(this.predictionDateToday.format('YYYY-MM-DD')),
        MapPredictionService.getBlueSkyFireSmokeMap(this.predictionDateToday.format('YYYY-MM-DD')),
        MapPredictionService.getWindyCams(this.mapDefaultBounds.getNorth(), this.mapDefaultBounds.getEast(), this.mapDefaultBounds.getSouth(), this.mapDefaultBounds.getWest(), this.mapDefaultZoom),
        MapPredictionService.getUSABarometric(this.predictionDateToday.format('YYYY-MM-DD'), 4),
        MapPredictionService.getUSAWind(this.predictionDateToday.format('YYYY-MM-DD'), 4),
      ])
      .then(
        axios.spread((...responses) => {
          const resFirePerimeter = responses[0];
          const resNoaaFireSmoke = responses[1].data.data;
          const resBlueSkyFireSmoke = responses[2].data.data;
          const resNoaaFireSmokeUSA = resNoaaFireSmoke.usa;
          const resNoaaFireSmokeAlaska = resNoaaFireSmoke.alaska;
          const resWindyCams = responses[3];
          const resBarometricLines = responses[4];
          const resFireWindUSA = responses[5];

          this.setDimensionTimes(resNoaaFireSmokeUSA);
          this.setState({
            firePerimeterGeoJson: resFirePerimeter.data.data,
            noaaFiresmokeUSAData: resNoaaFireSmokeUSA,
            noaaFiresmokeAlaskaData: resNoaaFireSmokeAlaska,
            blueskyFiresmokeData: resBlueSkyFireSmoke,
            noaaFiresmokeUrlUSA: resNoaaFireSmokeUSA[0].url,
            noaaFiresmokeUrlAlaska: resNoaaFireSmokeAlaska[0].url,
            blueskyFiresmokeUrl: resBlueSkyFireSmoke[0].url,
            windyCams: resWindyCams.data.data,
            barometricLines: resBarometricLines.data.data,
            fireWindUSA: resFireWindUSA.data.data,
            loaded: true,
          });
          resNoaaFireSmokeUSA.forEach((el: { url: string; }) => {
            new Image().src = el.url;
          });
          resNoaaFireSmokeAlaska.forEach((el: { url: string; }) => {
            new Image().src = el.url;
          });
          resBlueSkyFireSmoke.forEach((el: { url: string; }) => {
            new Image().src = el.url;
          });
          responses.forEach(el => {
            if(el.data.code !== 200) {
              this.snackbarOpen(el.data.status, 'warning');
            }
          });
        })
      )
      .catch(error => {
        console.error(error);
        this.setState({loaded: true});
        this.snackbarOpen(error.message);
      });
  }

  getPredictionPerimeterData() {
    MapPredictionService.getFirePerimeterPredictionMap(this.predictionDateTomorrow.format('YYYY-MM-DD'))
      .then(res => {
        this.setState({firePerimeterPredictionGeoJson: res.data.data});
        if(res.data.code !== 200) {
          this.snackbarOpen(res.data.status, 'warning');
        }
      })
      .catch(error => {
        console.error(error);
        this.snackbarOpen(error.message);
      });
  }

  setDimensionTimes(times: any[]): void {
    const timesArr = times.map(el => `${el.date}T${el.time}`);
    this.timeDimension.setAvailableTimes('', 'replace');
    this.timeDimension.setAvailableTimes(timesArr.join(','), 'replace');
    this.prevDate = moment(timesArr[0]);
  }

  changePredictionDate(value: moment.Moment) {
    this.leafletRef.current.timeDimensionControl._player.stop();
    this.setState({loaded: false});
    this.predictionDateToday = value;
    this.predictionDateTomorrow = value.subtract(-1, 'days');
    this.getAllMapData();
    this.getPredictionPerimeterData();
  }

  openInciwebData(title: string, name: string) {
    this.setState({
      inciwebTitle: title,
      inciwebName: name,
      isPaneOpenInciweb: true
    });
  }

  openWindyCam(camProps: any) {
    this.setState({
      windyCamProps: camProps,
      isPaneOpenWindyCam: true
    });
  }

  onEachFirePerimeter(feature: any, layer: Layer) {
    layer.on({
      'click': function (e: any): void {
        console.log('e: ', e);
        console.log('feature', feature);
      }
    });
    if (Object.keys(feature.properties).length !== 0) {
      const props = feature.properties;
      const title = props.incident_name ? props.incident_name : null;
      const popupContent = ReactDOMServer.renderToString(
        <div className="FirePoint-popup">
          <h1>{title}</h1>
          <div className="FirePoint-popup__body">
            <p><b>Incident type:</b> {props.incident_type ? props.incident_type : 'Not defined'}</p>
            <p><b>Incident map method:</b> {props.geometry_map_method ? props.geometry_map_method : 'Not defined'}</p>
            <p><b>Start date:</b> {props.start_date ? new Date(props.start_date).toLocaleString() : 'Not defined'}</p>
            <p><b>Update date:</b> {props.update_date ? new Date(props.update_date).toLocaleString() : 'Not defined'}</p>
            <p><b>Percent contained:</b> {props.contained ? (props.contained * 100 + '%') : 'Not defined'}</p>
            <p><b>Latitude:</b> {props.geometry_lat}</p>
            <p><b>Longitude:</b> {props.geometry_lon}</p>
            {props.url ? <div className="FirePoint-popup__link"><button id="inciBtn">Go to Incident Detail »</button></div> : ''}
          </div>
          <div className="FirePoint-popup__footer">
            Data provided by {props.geometry_source === 'polygons-smartfire' ? 'SMARTFIRE' : props.geometry_source === 'points-inciweb' ? 'INCIWEB' : 'NIFC'}
          </div>
        </div>
      );
      layer.bindPopup(popupContent);
      if (props.geometry_source === 'points-inciweb') {
        layer.on('popupopen', () => {
          const inciBtn = document.getElementById('inciBtn');
          inciBtn?.addEventListener('click', () => {
            this.openInciwebData(title, props.attr_IncidentName);
          });
        });
        layer.on('popupclose', () => {
          const inciBtn = document.getElementById('inciBtn');
          inciBtn?.removeEventListener('click', () => {
            this.openInciwebData(title, props.attr_IncidentName);
          });
        });
      }
    }
  }

  styleFireperimeter(feature: any) {
    const props = feature.properties;
    if (props.geometry_source === 'points-inciweb') {
      return this.firePerimeterInciwebStyle;
    } else {
      return this.firePerimeterStyle;
    }
  }

  pointToLayerWindyCams(feature: any, latLng: any) {
    const icon = new Icon({
      iconUrl: markerIconCam,
      iconRetinaUrl: markerIconCam,
      iconSize: [15, 15],
      iconAnchor: [8, 8],
      // shadowUrl: markerShadow,
      // shadowSize: [23, 41],
      // shadowAnchor: [8, 43],
      tooltipAnchor: [4, -5],
      popupAnchor: [0, -20]
    });
    return L.marker(latLng, {icon: icon});
  }

  onEachWindyCam(feature: any, layer: Layer) {
    if (Object.keys(feature.properties).length !== 0) {
      const props = feature.properties;
      const tooltipContent = `
        <div class="WindyCams-tooltip">
          <img src="${props.preview}">
        </div>
      `;
      layer.bindTooltip(tooltipContent, {opacity: 1});
      if (this.isShowCameras === 'true') {
        layer.on('click', e => this.openWindyCam(props));
      }
    }
  }

  styleBarometricLines(feature: any) {
    const props = feature.properties;
    const style = {
      color : '#71c7ec',
      weight: 1,
      opacity: 0.35
    };
    if (props.isoline_value >= 103667) { style.color = '#ff0000'; }
    else if (props.isoline_value >= 103334) { style.color = '#ff4d00'; }
    else if (props.isoline_value >= 103001) { style.color = '#ff7400'; }
    else if (props.isoline_value >= 102668) { style.color = '#ff9a00'; }
    else if (props.isoline_value >= 102335) { style.color = '#ffc100'; }
    else if (props.isoline_value >= 102002) { style.color = '#0eff00'; }
    else if (props.isoline_value >= 101669) { style.color = '#1fc600'; }
    else if (props.isoline_value >= 101336) { style.color = '#089000'; }
    else if (props.isoline_value >= 101003) { style.color = '#0a5d00'; }
    else if (props.isoline_value >= 100670) { style.color = '#063b00'; }
    else if (props.isoline_value >= 100337) { style.color = '#005073'; }
    else if (props.isoline_value >= 100004) { style.color = '#107dac'; }
    else if (props.isoline_value >= 99671) { style.color = '#189ad3'; }
    else if (props.isoline_value >= 99338) { style.color = '#1ebbd7'; }
    else { style.color = '#71c7ec'; }
    return style;
  }

  snackbarOpen(message = 'Somthing wrong!', severity: AlertColor = 'error') {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
      alertSeverity: severity
    });
  }

  snackbarClose(event?: React.SyntheticEvent | Event, reason?: string) {
    this.setState({snackbarOpen: false});
  }



  render() {
    return (
      <div>
        <MapContainer
          whenCreated={ mapInstance => { this.leafletRef.current = mapInstance } }
          center={[39.828175, -98.5795]}
          zoom={this.mapDefaultZoom}
          scrollWheelZoom={true}
          timeDimension={this.timeDimension}
          timeDimensionControl
          timeDimensionControlOptions={this.timeDimensionControlOptions}
          >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OSP</a> |
              <a href="https://firesmoke.ca/">BlueSky</a> |
              <div class="Attr-logo"><a href="https://firemap.org/"><img src="logo-text.svg" ></a></div>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {this.state.loaded &&
          <LayersControl position="topright" collapsed={false}>
            <LayersControl.Overlay checked name="Smoke">
              <LayerGroup>
                <ImageOverlay
                  ref={this.imgNoaaUSARef}
                  url={this.state.noaaFiresmokeUrlUSA}
                  bounds={this.noaaUSABounds}
                  opacity={0.7}
                  zIndex={10}
                />
                <ImageOverlay
                  ref={this.imgNoaaAlaskaRef}
                  url={this.state.noaaFiresmokeUrlAlaska}
                  bounds={this.noaaAlaskaBounds}
                  opacity={0.7}
                  zIndex={10}
                />
                <ImageOverlay
                  ref={this.imgBlueskyRef}
                  url={this.state.blueskyFiresmokeUrl}
                  bounds={this.blueskyBounds}
                  opacity={0.7}
                  zIndex={10}
                />
              </LayerGroup>
            </LayersControl.Overlay>
            {/* <LayersControl.Overlay name="Pressure">
              <GeoJSON
                ref={this.barometrictLinesRef}
                data={this.state.barometricLines[0].collection as GeoJsonObject}
                style={this.styleBarometricLines.bind(this)} />
            </LayersControl.Overlay> */}
            <LayersControl.Overlay checked name="Fire">
              <LayerGroup>
                <GeoJSON
                  ref={this.firePerimeterPredictRef}
                  data={this.emptyGeoJson as GeoJsonObject}
                  style={this.firePerimeterPredictionStyle} />
                <GeoJSON
                  ref={this.firePerimeterRef}
                  data={this.state.firePerimeterGeoJson.collection}
                  style={this.styleFireperimeter.bind(this)}
                  onEachFeature={this.onEachFirePerimeter.bind(this)} />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Wind">
            {this.state.fireWindUSA.length > 0 &&
              <WindyLayer
                ref={this.leafletRef}
                displayValues={this.windOptinons.displayValues}
                displayOptions={this.windOptinons.displayOptions}
                data={this.state.fireWindUSA}
                maxVelocity={this.windOptinons.maxVelocity}
                opacity={this.windOptinons.opacity}
                colorScale={this.windOptinons.colorScale} />
            }
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Cameras">
              <WindyCams
                data={this.state.windyCams}
                pointToLayer={this.pointToLayerWindyCams}
                onEachFeature={this.onEachWindyCam.bind(this)} />
            </LayersControl.Overlay>
          </LayersControl>
          }
          {this.state.loaded &&
          <Control position='topright'>
            <MapDatePicker
              predictionDate={this.predictionDateToday}
              onPick={this.changePredictionDate.bind(this)}
            >
            </MapDatePicker>
          </Control>
          }
        </MapContainer>
        <SlidingPane
          className="firemap-slide"
          overlayClassName="Slide-overlay"
          isOpen={this.state.isPaneOpenInciweb}
          title={this.state.inciwebName}
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.setState({ isPaneOpenInciweb: false });
          }}
        >
          <InciwebExternalData
            inciwebTitle={this.state.inciwebTitle}
            onError={this.snackbarOpen.bind(this)}>
          </InciwebExternalData>
        </SlidingPane>
        <SlidingPane
          className="firemap-slide"
          overlayClassName="Slide-overlay"
          isOpen={this.state.isPaneOpenWindyCam}
          title={this.state.windyCamProps.name}
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.setState({ isPaneOpenWindyCam: false });
          }}
        >
          <WindyCamsVideo
            name={this.state.windyCamProps.name}
            camUrl={this.state.windyCamProps.player_day}
            city={this.state.windyCamProps.city}
            country={this.state.windyCamProps.country}
            region={this.state.windyCamProps.region}
            previewUpdated={this.state.windyCamProps.preview_updated}
            status={this.state.windyCamProps.status}>
          </WindyCamsVideo>
        </SlidingPane>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={6000}
          open={this.state.snackbarOpen}
          onClose={this.snackbarClose.bind(this)}
          message={this.state.snackbarMessage}
          key={'map'} >
          <Alert onClose={this.snackbarClose.bind(this)} severity={this.state.alertSeverity} sx={{ width: '100%' }}>
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
        {!this.state.loaded &&
          <Loader
            loadingText="Loading content from NASA, NOAA, US Forest Service, and local data partners . . ."
            loadingSubtext="this may take up to 30 seconds"
            clear={true} />
        }
      </div>
    );
  }
}