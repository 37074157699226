import './InciwebExternalData.css';
import React from "react";
import { Component } from "react";
import MapPredictionService from "../Services/map-predictions.service"
import Loader from "./Loader";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


type Props = {
  inciwebTitle: string,
  onError: any
};
type State = {
  loaded: boolean,
  highlightedActivity: any,
  incidentAnnouncement: any,
  incidentClosure: any,
  incidentContact: any,
  incidentCooperators: any,
  incidentInformation: any,
  incidentOverview: any,
  relatedLinks: any,
  resentArticles: any,
  unitInformation: any,
  tabValue: number
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export class InciwebExternalData extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loaded: false,
      highlightedActivity: '',
      incidentAnnouncement: '',
      incidentClosure: '',
      incidentContact: '',
      incidentCooperators: '',
      incidentInformation: '',
      incidentOverview: '',
      relatedLinks: '',
      resentArticles: '',
      unitInformation: '',
      tabValue: 0
    };
  }

  componentDidMount() {
    MapPredictionService.getInciwebData(this.props.inciwebTitle)
      .then(res => {
        const renderHTMLContent = (htmlContent: any) => React.createElement('div', {
          dangerouslySetInnerHTML: { __html: htmlContent},
        });
        this.setState({
          highlightedActivity: renderHTMLContent(res.data.data?.highlighted_activity),
          incidentAnnouncement: renderHTMLContent(res.data.data?.incident_announcement),
          incidentClosure: renderHTMLContent(res.data.data?.incident_closure),
          incidentContact: renderHTMLContent(res.data.data?.incident_contact),
          incidentCooperators: renderHTMLContent(res.data.data?.incident_cooperators),
          incidentInformation: renderHTMLContent(res.data.data?.incident_information),
          incidentOverview: renderHTMLContent(res.data.data?.incident_overview),
          relatedLinks: renderHTMLContent(res.data.data?.related_links),
          resentArticles: renderHTMLContent(res.data.data?.resent_articles),
          unitInformation: renderHTMLContent(res.data.data?.unit_information),
          loaded: true
        });
        if(res.data.code !== 200) {
          this.props.onError(res.data.status, 'warning');
        }
      })
      .catch(errors => {
        console.error(errors);
        this.props.onError(errors.message);
      });
  }

  a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  tabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({tabValue: newValue});
  };

  render() {
    if(this.state.loaded) {
      return (
        <div className="inci-data">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              { this.state.unitInformation }
            </Grid>
            <Grid item xs={12} md={6}>
              { this.state.incidentContact }
            </Grid>
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={this.state.tabValue} onChange={this.tabChange} aria-label="basic tabs example">
              <Tab label="Information" {...this.a11yProps(0)} />
              <Tab label="Announcements" {...this.a11yProps(1)} />
              <Tab label="Closures" {...this.a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={this.state.tabValue} index={0}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h2 className="title">Highlighted Activity</h2>
              </AccordionSummary>
              <AccordionDetails>
                { this.state.highlightedActivity }
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h2 className="title">Incident Overview</h2>
              </AccordionSummary>
              <AccordionDetails>
                { this.state.incidentOverview }
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <h2 className="title">Incident Information</h2>
              </AccordionSummary>
              <AccordionDetails>
                { this.state.incidentInformation }
              </AccordionDetails>
            </Accordion>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                { this.state.resentArticles }
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="related-links">
                  { this.state.relatedLinks }
                </div>
                <div className="cooperators">
                  { this.state.incidentCooperators }
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1}>
            { this.state.incidentAnnouncement }
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={2}>
            { this.state.incidentClosure }
          </TabPanel>
          <div className="inci-data--footer">
            Data Source: Inciweb (<a href='https://inciweb.nwcg.gov/'>inciweb.nwcg.gov</a>)
          </div>
        </div>
      );
    } else {
      return (
        <Loader loadingText='Loading data from Inciweb and other incident sources...' clear={true} />
      );
    }
  }
}